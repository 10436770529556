<script lang="ts" setup>
import type { Json } from '~/supabase.db'

const props = defineProps<{
  section: {
    component_name: string
    component_props: Json
    margin_bottom_rem: number
  }
}>()

const emit = defineEmits<{
  update: [componentProps: Json]
}>()

const component = computed(
  () => CMS_BLOCKS[props.section.component_name as CmsBlocksKey]?.component() ?? 'div',
)

const componentProps = computed(() => {
  const cmsBlock = CMS_BLOCKS[props.section.component_name as CmsBlocksKey]
  if (!cmsBlock) return {}

  const componentProps = toRaw(props.section.component_props)
  if (!componentProps || typeof componentProps !== 'object') return cmsBlock.defaultProps

  return mergeComponentDefaultProps(componentProps, cmsBlock.defaultProps)
})

const editMode = useEditMode()
</script>

<template>
  <div :class="editMode ? 'relative' : 'contents'">
    <component
      :is="component"
      v-if="component"
      v-bind="componentProps"
      :style="{
        marginBottom: editMode ? undefined : `${section.margin_bottom_rem}rem`,
      }"
      @update:props="(values: Json) => emit('update', values)"
    />
    <DevOnly v-else>
      <div>Component not found: {{ props.section.component_name }}</div>
    </DevOnly>
  </div>
</template>
